/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useState } from 'react'
import { Landing } from "../partials/landing"
import { AllCourses } from "../partials/allCourses"
import { post } from '../networking/requestService';
import { Loading } from '../partials/loading';
import { Error } from '../partials/error';
import { ConfigDataContext } from '../../modules/context/configDataContext';



const Home: FC = () => {

    const [courses, setCourses] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState("basTarih");
    const [orderType, setOrderType] = useState("asc");
    const {configData} = useContext(ConfigDataContext);

    const changeSortingAndOrder = (sorting:any,orderType:any) => {
        setSorting(sorting);
        setOrderType(orderType);
        // console.log("sort: ",sorting," order: ",orderType);
    }
    useEffect(() => {
        if(configData?.isAllCoursesEnabled === false){
            const requestObj = {
                fn: 'getProfileCampaigns',
              }
              post(requestObj).then(({data: {campaignCurrentList, campaignHistoryList}}) => {
                setCourses({
                    campaignList: [
                      ...campaignCurrentList,
                      ...campaignHistoryList
                    ]
                  });
                  setLoading(false);
              }).catch((err) => {
                setError({ text: err.response.data.text, code: err.response.status });
                setLoading(false);
              });
        }
        else{
            const requestObj = {
                "fn": "campaignListHomePage",
                status: ["Active", "Planning"],
                start: 1,
                rowsPerPage: 1000,
                isExport: 0,
                orderByColumn: sorting,
                orderType: orderType,
                campName: "**",
                isFromFeatured: 1,
                excludeSkillSoftCourse : 1,
                filterValuesList: '[{"fieldName":"campaign_is_deleted","fieldValue":"0","fieldType":"calculated","fieldFormat":"number","fieldSign":"equal"},{"fieldName":"campaign_course_id","fieldValue":"0","fieldType":"calculated","fieldFormat":"string","fieldSign":"notequal"}]'
            };
                post(requestObj)
                .then(({ data: { campaignList } }) => {
                    setCourses({ campaignList });
                    setLoading(false);
    
                })
                .catch((err) => {
                    setError({ text: err.response.data.text, code: err.response.status });
                    setLoading(false);
                });
        }
    }, [sorting,orderType]);
    
    return (
        <>
            <Landing />
            {
                loading ? <Loading />
                    :
                    error ? <Error text={error.text} code={error.code} />
                        : <AllCourses campaignList = {courses.campaignList}
                        //  sortAndOrder = {(sort:any,order:any) => changeSortingAndOrder(sort,order)}
                         />
            }
        </>
    )
}

export { Home }