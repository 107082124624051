export const changeSeoValues = (configData) => {
  // FavIcon
  if (configData?.imageUrls?.favIcon) {
    document.getElementById('favicon')?.setAttribute('href', configData.imageUrls.favIcon)
  }
  // Title
  if(configData?.texts?.seoTitle){
    document.title = configData.texts.seoTitle
  }
  // Description
  document.querySelector('meta[name="description"]')?.setAttribute(
    'content',
    configData?.texts?.seoDescription
      ? configData.texts.seoDescription
      : 'Learning Management System'
  )
}

export const addFontFaces = (configData) => {
  configData?.fontFaces?.forEach(fontFace => {
    let newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(fontFace));
    document.head.appendChild(newStyle);
  });
}

export const addBackgrounds = (root, configData) => {
  root?.style.setProperty("--landing-bg-desktop","url('"+configData?.imageUrls?.landingBgDesktop+"')")
  root?.style.setProperty("--landing-bg-tablet","url('"+configData?.imageUrls?.landingBgTablet+"')")
  root?.style.setProperty("--landing-bg-mobile","url('"+configData?.imageUrls?.landingBgMobile+"')")

  root?.style.setProperty("--login-bg-desktop","url('"+configData?.imageUrls?.loginBgDesktop+"')")
  root?.style.setProperty("--login-bg-tablet","url('"+configData?.imageUrls?.loginBgTablet+"')")
  root?.style.setProperty("--login-bg-mobile","url('"+configData?.imageUrls?.loginBgMobile+"')")
}

const colorsArray = [
  {cssColor: '--primary-background-color', configColor: 'pageBodyBgColor', defaultColor: 'red'},
  {cssColor: '--secondary-background-color', configColor: 'loginBgColor', defaultColor: 'red'},
  {cssColor: '--landing-background-color', configColor: 'heroBgColor', defaultColor: 'red'},
  {cssColor: '--welcome-title-color', configColor: 'loginHeadingColor', defaultColor: 'red'},
  {cssColor: '--welcome-subtitle-color', configColor: 'loginTextColor', defaultColor: 'red'},

  {cssColor: '--login-bg-color', configColor: 'loginBgColor', defaultColor: 'red'}, // 04.06.2024

  //{cssColor: '--login-form-input-text-color', configColor: 'inputTextColor', defaultColor: '#6787A9'},
  {cssColor: '--login-text-color', configColor: 'loginTextColor', defaultColor: '#6787A9'}, // 04.06.2024

  {cssColor: '--login-title-color', configColor: 'loginTitleColor', defaultColor: '#FFFFFF'}, // 04.06.2024

  // {cssColor: '--login-form-bg-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--form-bg-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'}, // 04.06.2024

  // {cssColor: '--login-form-input-bg-color', configColor: 'cardBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--input-bg-color', configColor: 'inputBgColor', defaultColor: '#FBFCFD'}, // 04.06.2024

  // {cssColor: '--login-form-input-text-color', configColor: 'inputTextColor', defaultColor: '#6787A9'},
  {cssColor: '--input-text-color', configColor: 'inputTextColor', defaultColor: '#6787A9'}, // 04.06.2024

  // {cssColor: '--login-form-input-border-color', configColor: 'landingTertiaryColor', defaultColor: '#E5EBF0'},
  {cssColor: '--input-border-color', configColor: 'inputBorderColor', defaultColor: '#E5EBF0'}, // 04.06.2024

  {cssColor: '--login-form-btn-bg-color', configColor: 'primaryButtonBgColor', defaultColor: '#023770'},
  {cssColor: '--login-form-btn-text-color', configColor: 'primaryButtonTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--login-form-links-color', configColor: 'loginActionColor', defaultColor: 'red'},
  {cssColor: '--input-invalid-border-color', configColor: 'inputErrorBorderColor', defaultColor: 'red'},
  {cssColor: '--form-error-color', configColor: 'inputErrorMessageColor', defaultColor: 'red'},
  {cssColor: '--form-box-shadow-color', configColor: "cardBoxShadowColor", defaultColor: 'red'},
  {cssColor: '--header-bg-color', configColor: 'headerBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--header-menu-item-color', configColor: 'headerMenuTextColor', defaultColor: '#6787A9'},
  {cssColor: '--header-selected-menu-item-color', configColor: 'headerMenuActiveTextColor', defaultColor: '#023770'},
  {cssColor: '--selected-menu-item-border-color', configColor: 'headerMenuActiveIndicatorColor', defaultColor: '#F39200'},
  {cssColor: '--header-dropdown-bg-color', configColor: 'headerMenuDropdownBgColor', defaultColor: 'red'},
  {cssColor: '--header-dropdown-selected-bg-color', configColor: 'headerMenuDropdownSelectedBgColor', defaultColor: 'red'},
  {cssColor: '--header-dropdown-item-text-color', configColor: 'headerMenuDropdownTextColor', defaultColor: 'red'},
  {cssColor: '--header-dropdown-item-subtext-color', configColor: 'headerMenuDropdownTextColor', defaultColor: 'red'},
  {cssColor: '--header-dropdown-text-color', configColor: 'headerMenuDropdownTextColor', defaultColor: 'red'},
  {cssColor: '--header-dropdown-selected-text-color', configColor: 'headerMenuDropdownSelectedTextColor', defaultColor: 'red'},
  {cssColor: '--header-search-input-text-color', configColor: 'inputTextColor', defaultColor: 'red'},
  {cssColor: '--footer-background-color', configColor: 'footerBgColor', defaultColor: '#E5EBF0'},
  {cssColor: '--footer-color', configColor: 'footerTextColor', defaultColor: '#6787A9'},
  {cssColor: '--hero-background-color', configColor: 'heroBgColor', defaultColor: '#E5EBF0'}, // 04.06.2024
  
  // {cssColor: '--landing-color', configColor: 'heroHeadingColor', defaultColor: '#6787A9'},
  {cssColor: '--hero-heading-color', configColor: 'heroHeadingColor', defaultColor: '#6787A9'}, // 04.06.2024
  
  // {cssColor: '--landing-tertiary-color', configColor: 'heroTextColor', defaultColor: '#023770'},
  {cssColor: '--hero-text-color', configColor: 'heroTextColor', defaultColor: '#023770'}, // 04.06.2024

  {cssColor: '--scrollTop-background-color', configColor: 'scrollTopButtonBgColor', defaultColor: 'red'},
  {cssColor: '--scrollTop-color', configColor: 'scrollTopButtonIconColor', defaultColor: 'red'},
  // {cssColor: '--course-content-bg-color', configColor: '', defaultColor: 'red'}, karşılığı yok
  {cssColor: '--all-courses-navbar-bg-color', configColor: 'courseTabBarBgColor', defaultColor: 'red'},
  {cssColor: '--all-courses-navbar-title-color', configColor: 'courseTabBarHeadingColor', defaultColor: 'red'},
  {cssColor: '--all-courses-navbar-play-icon-color', configColor: 'courseTabBarHeadingIconColor', defaultColor: 'red'},
  {cssColor: '--all-courses-grid-item-background-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--all-courses-item-time-length-color', configColor: 'cardLessonDurationColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-subtitle-color', configColor: 'cardTextColor', defaultColor: 'red'},
  {cssColor: '--all-courses-item-title-color', configColor: 'cardHeadingColor', defaultColor: 'red'},
  {cssColor: '--all-courses-item-no-course-color', configColor: 'noCourseTextColor', defaultColor: 'red'},
  {cssColor: '--all-courses-item-button-background-color', configColor: 'cardBgColor', defaultColor: '#E5EBF0'},
  
  // css degiştir button disabled olacak
  {cssColor: '--all-courses-item-button-primary-background-color', configColor: 'primaryButtonBgColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-item-button-color', configColor: 'primaryGhostButtonTextColor', defaultColor: 'red'},// sadece button text colorı işaret ediyor ??
  {cssColor: '--all-courses-item-button-primary-color', configColor: 'primaryButtonTextColor', defaultColor: '#FBFCFD'},
  
  
  {cssColor: '--myCourses-navbar-bg-color', configColor: 'courseTabBarBgColor', defaultColor: 'red'},
  {cssColor: '--myCourses-navbar-text-color', configColor: 'courseTabBarLinkColor', defaultColor: 'red'},
  {cssColor: '--myCourses-navbar-selected-text-color', configColor: 'courseTabBarActiveLinkColor', defaultColor: 'red'},  
  {cssColor: '--selected-navbar-item-border-color', configColor: 'courseTabBarActiveLinkIndicatorColor', defaultColor: 'red'},
  
  {cssColor: '--progress-empty-color', configColor: 'cardChartBgColor', defaultColor: 'red'},
  {cssColor: '--progress-fill-color', configColor: 'cardChartPercentilColor', defaultColor: 'red'},

  {cssColor: '--myCourse-subjects-background-color', configColor: 'courseVideoContentBgColor', defaultColor: '#6787A9'},
  {cssColor: '--myCourse-subjects-items-background-color', configColor: 'courseVideoContentItemsBgColor', defaultColor: '#526C87'},
  {cssColor: '--myCourse-subjects-items-active-bg-color', configColor: 'courseVideoContentItemsCheckIconColor', defaultColor: '#34475C'},
  
  {cssColor: '--myCourse-subjects-color', configColor: 'courseVideoContentItemsTextColor', defaultColor: 'red'},
  {cssColor: '--myCourse-subjects-items-color', configColor: 'courseVideoContentItemsTextColor', defaultColor: '#FBFCFD'},
  
  {cssColor: '--myCourse-subjects-lang-toggle-background-color', configColor: 'courseVideoContentLangToggleBgColor', defaultColor: 'red'},
  {cssColor: '--myCourse-subjects-lang-toggle-text-color', configColor: 'courseVideoContentLangToggleTextColor', defaultColor: 'red'},
  
  {cssColor: '--myCourse-subjects-finished-tick-color', configColor: 'courseVideoContentItemsCheckIconColor', defaultColor: 'red'},
  {cssColor: '--myCourse-subjects-scrollbar-color', configColor: 'courseVideoContentScrollbarColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-bg-color', configColor: 'courseQuizBgColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-title-color', configColor: 'courseQuizMessageColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-text-primary-color', configColor: 'courseQuizTextColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-success-color', configColor: 'courseQuizSuccessPointColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-failure-color', configColor: 'courseQuizFailurePointColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-text-secondary-color', configColor: 'courseQuizSubText', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-btn-bg-color', configColor: 'courseQuizButtonBgColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-btn-text-color', configColor: 'courseQuizButtonTextColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-secondary-btn-bg-color', configColor: 'courseQuizSecondaryButtonBgColor', defaultColor: 'red'},
  {cssColor: '--myCourse-quizResult-secondary-btn-text-color', configColor: 'courseQuizSecondaryButtonTextColor', defaultColor: 'red'},
  {cssColor: '--myCourse-details-title-color', configColor: 'courseDetailHeadingColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-details-text-color', configColor: 'courseDetailTextColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-play-btn-bg-color', configColor: 'secondaryButtonBgColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-play-btn-text-color', configColor: 'secondaryButtonTextColor', defaultColor: '#FBFCFD'},
  
  {cssColor: '--myCourse-play-again-btn-text-color', configColor: 'primaryGhostButtonTextColor', defaultColor: 'red'},
  
  {cssColor: '--myCourse-completed-text-color', configColor: 'courseCompletedBagdeTextColor', defaultColor: 'red'},
  {cssColor: '--faq-background-color', configColor: 'faqCardBgColor', defaultColor: '#FFFFFF'},
  //icon color diye degiştir
  {cssColor: '--faq-button-color', configColor: 'faqCardIconColor', defaultColor: 'red'},
  {cssColor: '--faq-question-color', configColor: 'faqQuestionColor', defaultColor: 'red'},
  {cssColor: '--faq-answer-color', configColor: 'faqAnswerColor', defaultColor: 'red'},

  // button degil card color olacak // button color  degil text color olacak
  {cssColor: '--certificate-button-background-color', configColor: 'certificateCardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--certificate-button-color', configColor: 'certificateCardTextColor', defaultColor: 'red'},
  {cssColor: '--certificate-download-icon-color', configColor: 'certificateCardIconColor', defaultColor: 'red'},

  //css degiştir
  {cssColor: '--no-course-heading-color', configColor: 'courseEmptyStateHeadingColor', defaultColor: '#023770'},
  {cssColor: '--no-course-text-color', configColor: 'courseEmptyStateTextColor', defaultColor: 'red'},
  {cssColor: '--no-course-btn-bg-color', configColor: 'courseEmptyStateButtonBgColor', defaultColor: 'red'},
  {cssColor: '--no-course-btn-text-color', configColor: 'courseEmptyStateButtonTextColor', defaultColor: 'red'},
  
  {cssColor: '--profile-primary-text-color', configColor: 'pageHeadingColor', defaultColor: 'red'},
  {cssColor: '--profile-form-bg-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--profile-form-btn-bg-color', configColor: 'primaryButtonBgColor', defaultColor: '#023770'},
  {cssColor: '--profile-form-btn-text-color', configColor: 'primaryButtonTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-form-input-text-color', configColor: 'inputTextColor', defaultColor: '#6787A9'},
  {cssColor: '--profile-form-input-bg-color', configColor: 'inputBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-form-input-border-color', configColor: 'inputBorderColor', defaultColor: '#E5EBF0'},

  {cssColor: '--fallback-text-color', configColor: '', defaultColor: 'red'}, // ? body text color

  //css degiştir sayfadaki text colorların hepsi buradan geliyor neredeyse
  {cssColor: '--career-title-primary-color', configColor: 'pageHeadingColor', defaultColor: 'red'},

  //css degiştir
  {cssColor: '--career-title-secondary-color', configColor: 'careerHeroHeadingColor', defaultColor: 'red'},
  
  // yeni geldi css 
  {cssColor: 'careerHeroHeadingIndicatorColor', configColor: 'careerHeroHeadingIndicatorColor', defaultColor: 'red'},

  {cssColor: '--career-title-tertiary-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--career-btn-primary-bg-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--career-btn-primary-text-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--career-posProgress-bg-color', configColor: 'cardBgColor', defaultColor: '#8E9AA0'},
  {cssColor: '--career-actualpos-color', configColor: 'careerGraphActualColor', defaultColor: '#D1AE22'},
  {cssColor: '--career-level1pos-color', configColor: 'careerGrahphLevel1Color', defaultColor: '#00AED6'},
  {cssColor: '--career-level2pos-color', configColor: 'careerGrahphLevel2Color', defaultColor: '#B05C83'},
  {cssColor: '--career-posDetails-text-color', configColor: 'careerHeroTextColor', defaultColor: 'red'},
  {cssColor: '--career-selector-bg-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--career-selector-text-color', configColor: 'pageTextColor', defaultColor: 'red'},
  {cssColor: '--career-graph-actual-bg-color', configColor: 'careerGraphActualColor', defaultColor: 'red'},
  {cssColor: '--career-graph-required-bg-color', configColor: 'careerGraphRequiredColor', defaultColor: 'red'},
  {cssColor: '--devplan-primary-text-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--devplan-table-border-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--devplan-btn-primary-text-color', configColor: '', defaultColor: 'red'},
  {cssColor: '--errorpage-icon-color', configColor:'', defaultColor: 'red'},
  {cssColor: '--errorpage-title-color', configColor:'', defaultColor: 'red'},
  {cssColor: '--errorpage-text-color', configColor:'', defaultColor: 'red'},
  {cssColor: '--errorpage-btn-bg-color', configColor:'primaryButtonBgColor', defaultColor: 'red'},
  {cssColor: '--errorpage-btn-text-color', configColor:'primaryButtonTextColor', defaultColor: 'red'},
  {cssColor: '--error-404-page-bg-color', configColor:'page404BgColor', defaultColor: '#023770'},
  {cssColor: '--error-500-page-bg-color', configColor:'page500BgColor', defaultColor: '#FBFCFD'}
];
const presetColors = {
    primaryButtonBgColor: "primaryColor500",
    primaryButtonTextColor: "primaryColor50",
    primaryButtonDisableBgColor: "primaryColor100",
    primaryButtonDisableTextColor: "primaryColor300",
    primaryGhostButtonBgColor: "transparent",
    primaryGhostButtonTextColor: "primaryColor400",
    primaryGhostButtonDisableBgColor: "transparent",
    primaryGhostButtonDisableTextColor: "primaryColor300",
    secondaryButtonBgColor: "secondaryColor500",
    secondaryButtonTextColor: "secondaryColor50",
    secondaryButtonDisableBgColor: "secondaryColor100",
    secondaryButtonDisableTextColor: "secondaryColor200",
    secondaryGhostButtonBgColor: "transparent",
    secondaryGhostButtonTextColor: "secondaryColor500",
    secondaryGhostButtonDisableBgColor: "transparent",
    secondaryGhostButtonDisableTextColor: "secondaryColor200",
    tertiaryButtonBgColor: "tertiaryColor500",
    tertiaryButtonTextColor: "tertiaryColor50",
    tertiaryGhostButtonBgColor: "transparent",
    tertiaryGhostButtonTextColor: "tertiaryColor500",
    inputBgColor: "primaryColor50",
    inputPlaceholderColor: "primaryColor400",
    inputTextColor: "primaryColor500",
    inputBorderColor: "primaryColor300",
    inputIconColor: "primaryColor400",
    inputIconActiveColor: "primaryColor500",
    inputErrorBorderColor: "secondaryColor500",
    inputErrorMessageColor: "secondaryyColor500",
    headerBgColor: "primaryColor100",
    headerMenuTextColor: "primaryColor500",
    headerMenuActiveTextColor: "primaryColor500",
    headerMenuActiveIndicatorColor: "primaryColor500",
    headerActionsTextColor: "primaryColor500",
    headerMenuDropdownBgColor: "primaryColor50",
    headerMenuDropdownTextColor: "sprimaryColor400",
    headerMenuDropdownSelectedBgColor: "primaryColor500",
    headerMenuDropdownSelectedTextColor: "primaryColor100",
    footerBgColor: "primaryColor500",
    footerTextColor: "primaryColor200",
    page404BgColor: "secondaryColor500", 
    page404CodeColor: "primaryColor500", 
    page404HeadingColor: "primaryColor500", 
    page404TextColor: "primaryColor400", 
    page500BgColor: "tertiaryColor50", 
    page500CodeColor: "primaryColor500", 
    page500HeadingColor: "primaryColor500", 
    page500TextColor: "primaryColor400", 
    loginBgColor: "primaryColor100",
    loginHeadingColor: "primaryColor500",
    loginTextColor: "primaryColor400",
    loginActionColor: "primaryColor400",
    heroBgColor: "primaryColor500",
    heroHeadingColor: "primaryColor100",
    heroTextColor: "primaryColor200",
    pageBodyBgColor: "primaryColor100",
    pageHeadingColor: "primaryColor500",
    pageTextColor: "primaryColor400",
    pageHeadingIndicatorColor: "primaryColor500",
    courseEmptyStateBgColor: "primaryColor100",
    courseEmptyStateHeadingColor: "primaryColor500",
    courseEmptyStateTextColor: "primaryColor400",
    courseEmptyStateButtonBgColor: "primaryColor500",
    courseEmptyStateButtonTextColor: "primaryColor50",
    courseTabBarBgColor: "primaryColor300",
    courseTabBarHeadingIconColor: "primaryColor400",
    courseTabBarHeadingColor: "primaryColor400",
    courseTabBarActionColor: "primaryColor400",
    courseTabBarLinkColor: "primaryColor400",
    courseTabBarActiveLinkColor: "primaryColor500",
    courseTabBarActiveLinkIndicatorColor: "primaryColor500",
    courseSectionBgColor: "primaryColor100",
    noCourseTextColor: "primaryColor500",
    cardBgColor: "primaryColor50",
    cardHeadingColor: "primaryColor500",
    cardTextColor: "primaryColor400",
    cardLessonDurationColor: "secondaryColor500",
    cardIconColor: "secondaryColor500",
    cardChartLabel: "primaryColor400",
    cardChartBgColor: "primaryColor300",
    cardChartPercentilColor: "primaryColor400",
    cardBoxShadowColor: "dropShadowColor",
    courseDetailHeadingColor: "primaryColor500",
    courseDetailHeadingIndicatorColor: "primaryColor500",
    courseDetailTextColor: "primaryColor400",
    courseVideoContentBgColor: "primaryColor500",
    courseVideoContentScrollbarBgColor: "primaryColor300",
    courseVideoContentScrollbarColor: "secondaryColor400",
    courseVideoContentItemsBgColor: "primaryColor400",
    courseVideoContentItemsTextColor: "primaryColor100",
    courseVideoContentItemsCheckIconColor: "tertiaryColor500",
    courseVideoContentLangToggleTextColor: "primaryColor100",
    courseVideoContentLangToggleBgColor: "primaryColor500",
    courseVideoContentLangToggleBorderColor: "primaryColor100",
    courseQuizBgColor: "primaryColor50",
    courseQuizMessageColor: "secondaryColor500",
    courseQuizTextColor: "secondaryColor400",
    courseQuizSubText: "primaryColor400",
    courseQuizSuccessPointColor: "tertiaryColor500",
    courseQuizFailurePointColor: "secondaryColor500",
    courseQuizButtonBgColor: "primaryColor500",
    courseQuizButtonTextColor: "primaryColor50",
    courseQuizSecondaryButtonBgColor: "transparent",
    courseQuizSecondaryButtonTextColor: "primaryColor100",
    courseCompletedBagdeBgColor: "transparent",
    courseCompletedBagdeBorderColor: "primaryColor100",
    courseCompletedBagdeTextColor: "primaryColor50",
    certificateCardBgColor: "primaryColor50",
    certificateCardIconColor: "primaryColor500",
    certificateCardTextColor: "primaryColor400",
    faqCardBgColor: "primaryColor50",
    faqCardIconColor: "primaryColor500",
    faqQuestionColor: "primaryColor400",
    faqAnswerColor: "primaryColor400",
    careerHeroBgColor: "primaryColor500",
    careerHeroHeadingColor: "primaryColor100",
    careerHeroHeadingIndicatorColor: "primaryColor200",
    careerHeroTextColor: "primaryColor100",
    careerHeroPercentileColor: "primaryColor300",
    careerGraphActualColor: "secondaryColor500",
    careerGrahphLevel1Color: "tertiaryColor500",
    careerGrahphLevel2Color: "primaryColor300",
    careerGraphRequiredColor: "primaryColor200",
    careerGraphLabelColor: "primaryColor500",
    careerGraphRefLabelColor: "primaryColor300",
    careerGraphLineColor: "primaryColor200",
    scrollTopButtonBgColor: "tertiaryColor500",
    scrollTopButtonIconColor: "tertiaryColor50",
    devplanTableLineColor: "primaryColor200",
    devplanTableHeadingColor: "primaryColor500",
    devplanTableTextColor: "primaryColor400",
    devplanStatusBadgeBgColor: "transparent",
    devplanStatusBadgeBorderColor: "tertiaryColor500",
    devplanStatusBadgeTextColor: "tertiaryColor500"
}

export const applyColors = (root, configData) => {
  const mergedColors = {...presetColors,...configData?.colors }
  for (const obj of colorsArray) {
    if(mergedColors){
      root?.style.setProperty(obj.cssColor, configData?.themeColors[mergedColors[obj.configColor]])
    } else {
      root?.style.setProperty(obj.cssColor, obj.defaultColor)
    }
  }
}
